/**
 * frontend/src/components/ProjectInvite.tsx
 */
import React, { useState } from "react";
import apiClient from "../api/client";
import axios from "axios";

interface ProjectInviteProps {
  projectId: string;
  onInviteSuccess: () => void;
}

const ProjectInvite: React.FC<ProjectInviteProps> = ({ projectId, onInviteSuccess }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    try {
      const response = await apiClient.post(`/projects/${projectId}/invite_member/`, {
        invitee_email: email,
      });
      console.log("招待のレスポンス:", response.data);
      setEmail("");
      onInviteSuccess();
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        setError(
          `招待の送信に失敗しました: ${JSON.stringify(err.response.data)}`
        );
      } else {
        setError("招待の送信に失敗しました。もう一度お試しください。");
      }
      console.error("招待のエラー:", err);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="User's email address"
        className="w-full p-2 border rounded"
        required
      />
      <button
        type="submit"
        className="mt-2 bg-blue-500 text-white px-4 py-2 rounded"
      >
        Invite
      </button>
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </form>
  );
};

export default ProjectInvite;
