import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import apiClient from "../api/client";

const InvitationAccept: React.FC = () => {
  const { invitationId } = useParams<{ invitationId: string }>();
  const navigate = useNavigate();
  // const location = useLocation();
  const [message, setMessage] = useState<string>("Processing...");

  useEffect(() => {
    const acceptInvitation = async () => {
      console.log("Attempting to accept invitation with ID:", invitationId);
      try {
        const response = await apiClient.post(`/invitations/${invitationId}/accept/`);
        console.log("Invitation accepted:", response.data);
        setMessage("Invitation accepted. Redirecting to projects.");
        setTimeout(() => {
          navigate("/projects");
        }, 3000);
      } catch (error) {
        console.error("Error accepting invitation:", error);
        if (axios.isAxiosError(error) && error.response) {
          if (error.response.status === 401) {
            console.log("Authentication error. Redirecting to login.");
            navigate("/login", { state: { from: `/invitations/${invitationId}/accept` } });
          } else if (error.response.status === 404) {
            setMessage("Invitation not found or already accepted.");
          } else {
            setMessage(`Error: ${JSON.stringify(error.response.data)}`);
          }
        } else {
          setMessage("An error occurred. Please try again.");
        }
      }
    };

    // ユーザーがログインしているか確認
    const user = localStorage.getItem('token');
    if (user) {
      console.log("User is logged in.");
      acceptInvitation();
    } else {
      console.log("User is not logged in.");
      setMessage("Please log in to accept the invitation.");
      navigate("/login", { state: { from: `/invitations/${invitationId}/accept` } });
    }
  }, [invitationId, navigate]);

  return (
    <div className="container mx-auto px-4">
      <h1 className="text-2xl font-bold">{message}</h1>
    </div>
  );
};

export default InvitationAccept;
