// frontend/src/components/AuthContext.tsx
import React, { createContext, useState, useContext, ReactNode, useEffect } from "react";
import apiClient from "../api/client";

interface User {
  id: string;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  company: string;
}

interface AuthContextType {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  logout: () => void;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(localStorage.getItem("token"));

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    setUser(null);
    setToken(null);
  };

  useEffect(() => {
    if (token) {
      const fetchUser = async () => {
        try {
          const response = await apiClient.get("/users/me/");
          setUser(response.data);
        } catch (error) {
          console.error("ユーザー情報の取得に失敗しました:", error);
          logout();
        }
      };
      fetchUser();
    } else {
      setUser(null);
    }
  }, [token]);

  return (
    <AuthContext.Provider value={{ user, setUser, logout, setToken }}>
      {children}
    </AuthContext.Provider>
  );
};

// export const useAuth = () => {
//   const context = useContext(AuthContext);
//   if (context === undefined) {
//     throw new Error("useAuth must be used within an AuthProvider");
//   }
//   return context;
// };
