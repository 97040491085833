// frontend/src/components/ProjectList.tsx
import React from "react";
import { Link } from "react-router-dom";
import { useProjects } from "../hooks/useProjects";
// import { CustomError } from "../types/CustomError";

interface Project {
  id: number;
  name: string;
  description: string;
  start_date: string;
  end_date: string;
  status: string;
  // 他のフィールド...
}

const ProjectList: React.FC = () => {
  const token = localStorage.getItem("token");
  const { projects, loading, error } = useProjects(token);

  const formatDate = (dateString: string) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date.getTime())
      ? date.toLocaleDateString()
      : "Invalid Date";
  };

  if (!token) {
    return (
      <div className="text-center py-4">
        You need to be logged in to view projects.
      </div>
    );
  }

  if (loading) return <div className="text-center py-4">読み込み中...</div>;

  if (error) {
    if (error.status === 401) {
      return (
        <div className="text-center py-4 text-red-500">
          認証に失敗しました。再度ログインしてください。
        </div>
      );
    }
    return (
      <div className="text-center py-4 text-red-500">
        エラー: {error.message || "プロジェクトの取得に失敗しました"}
      </div>
    );
  }

  if (projects.length === 0)
    return <div className="text-center py-4">プロジェクトが見つかりません</div>;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {projects.map((project) => (
        <div key={project.id} className="bg-white shadow-md rounded-lg p-4">
          <h2 className="text-xl font-bold mb-2">
            <Link
              to={`/projects/${project.id}`}
              className="text-blue-600 hover:text-blue-800"
            >
              {project.name}
            </Link>
          </h2>
          <p className="text-gray-600 mb-2 line-clamp-2">
            {project.description}
          </p>
          <div className="flex justify-between items-center mt-2">
            <div>
              <p className="text-sm text-gray-500">
                Start: {formatDate(project.start_date)}
              </p>
              <p className="text-sm text-gray-500">
                End: {formatDate(project.end_date)}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjectList;
