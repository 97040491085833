import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import apiClient from "../api/client";
import axios from "axios";

interface UserData {
  id: string; // UUIDは文字列として扱う
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  role: string;
  company: string;
}

const UserProfileView: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await apiClient.get(`/users/${id}/profile/`);
        setUserData(response.data);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          if (err.response.status === 403) {
            setError(
              "このユーザーのプロファイルにアクセスする権限がありません。"
            );
          } else if (err.response.status === 404) {
            setError("ユーザーが見つかりません。");
          } else {
            setError("プロファイルの取得に失敗しました。");
          }
        } else {
          setError("プロファイルの取得に失敗しました。");
        }
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchUserProfile();
    }
  }, [id]);

  if (loading) return <div className="text-center mt-8">Loading...</div>;

  if (error)
    return <div className="text-center mt-8 text-red-500">{error}</div>;

  if (!userData)
    return <div className="text-center mt-8">This user does not exist.</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="px-6 py-4">
          <h1 className="text-2xl font-bold mb-4">{userData.username}</h1>
          <div className="space-y-2">
            <ProfileField label="Email" value={userData.email} />
            <ProfileField label="First Name" value={userData.first_name} />
            <ProfileField label="Last Name" value={userData.last_name} />
            <ProfileField label="Role" value={userData.role} />
            <ProfileField label="Company" value={userData.company} />
          </div>
        </div>
      </div>
    </div>
  );
};

const ProfileField: React.FC<{ label: string; value: string }> = ({ label, value }) => (
  <div>
    <span className="font-semibold">{label}: </span>
    <span>{value}</span>
  </div>
);

export default UserProfileView;
