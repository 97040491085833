// frontend/src/components/ProjectDetail.tsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import apiClient from "../api/client";
import ProjectInvite from "./ProjectInvite";
import ProjectCreationForm from "./ProjectCreationForm";
// import { Project,} from "../types/project";


interface User {
  id: string;
  username: string;
}

interface Thread {
  id: string;
  name: string;
}

interface Project {
  id: string;
  name: string;
  description: string;
  start_date: string;
  end_date: string;
  status: string;
  project_managers: Array<User>;
  members: Array<User>;
  threads: Thread[];
}

const ProjectDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [project, setProject] = useState<Project | null>(null);
  const [newThreadName, setNewThreadName] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProject = async () => {
      if (id === "new") {
        setLoading(false);
        return;
      }
      try {
        const response = await apiClient.get(`/projects/${id}/`);
        console.log("取得したプロジェクトデータ:", response.data); // デバッグ用
        setProject(response.data);
        setLoading(false);
      } catch (err) {
        console.error("プロジェクトを取得できませんでした。", err);
        setError("プロジェクトを取得できませんでした。");
        setLoading(false);
      }
    };
    fetchProject();
  }, [id]);

  const handleCreateThread = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await apiClient.post("/chat/threads/", {
        project: id,
        name: newThreadName,
      });
      setProject((prevProject: Project | null) => {
        if (prevProject === null) return null;
        return {
          ...prevProject,
          threads: [...(prevProject.threads || []), response.data],
        };
      });
      setNewThreadName("");
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.error("Failed to create thread:", err.response?.data);
        setError(
          `Failed to create thread: ${
            err.response?.data?.detail || err.message
          }`
        );
      } else {
        console.error("An unexpected error occurred:", err);
        setError("An unexpected error occurred");
      }
    }
  };

  if (loading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  if (id === "new") {
    return (
      <div className="max-w-2xl mx-auto mt-8 p-6 bg-white shadow-md rounded-lg">
        <h1 className="text-3xl font-bold mb-4">Create New Project</h1>
        <ProjectCreationForm />
      </div>
    );
  }
  if (error || !project) {
    return <div className="text-center mt-8">Project not found.</div>;
  }

  return (
    <div className="max-w-2xl mx-auto mt-8 p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-3xl font-bold mb-4">{project.name}</h1>
      <p className="text-gray-600 mb-4">{project.description}</p>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <p className="font-semibold">Start:</p>
          <p>{project.start_date}</p>
        </div>
        <div>
          <p className="font-semibold">End:</p>
          <p>{project.end_date}</p>
        </div>
      </div>
      <div className="mt-4">
        <p className="font-semibold">Project Managers:</p>
        <ul>
          {project.project_managers.map((manager: User) => (
            <li key={manager.id}>
              <Link to={`/profile/${manager.id}`} className="text-blue-500 hover:text-blue-700">
                {manager.username}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-4">
        <p className="font-semibold">Members:</p>
        <ul>
          {project.members.map((member: User) => (
            <li key={member.id}>
              <Link to={`/profile/${member.id}`} className="text-blue-500 hover:text-blue-700">
                {member.username}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      {/* スレッド作成フォーム */}
      <div className="mt-4">
        <h2 className="text-xl font-semibold mb-2">Chat Threads</h2>
        <form onSubmit={handleCreateThread} className="mb-4 flex flex-col">
          <input
            type="text"
            value={newThreadName}
            onChange={(e) => setNewThreadName(e.target.value)}
            placeholder="New thread name"
            className="border rounded p-2 mb-2 w-full"
            required
          />
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Create Thread
          </button>
        </form>
        {project.threads && project.threads.length > 0 ? (
          project.threads.map((thread: Thread) => (
            <Link
              key={thread.id}
              to={`/chat/threads/${thread.id}`}
              className="block p-2 mb-2 bg-gray-100 rounded hover:bg-gray-200"
            >
              {thread.name}
            </Link>
          ))
        ) : (
          <p>No chat threads available.</p>
        )}
      </div>

      <ProjectInvite projectId={project.id.toString()} onInviteSuccess={() => {
        // メンバーリストを再取得するためにプロジェクトデータを再フェッチ
        apiClient.get(`/projects/${id}/`).then(response => {
          setProject(response.data);
        });
      }} />
    </div>
  );
};

export default ProjectDetail;
