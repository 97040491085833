// frontend/src/App.tsx
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import ProjectDetail from "./components/ProjectDetail";
import ThreadDetail from "./components/ThreadDetail";
import Login from "./components/Login";
import Register from "./components/Register";
import EmailConfirmation from "./components/EmailConfirmation";
import UserProfile from "./pages/UserProfile";
import { AuthProvider} from "./components/AuthContext";
import { useAuth } from "./hooks/useAuth";
import InvitationAccept from "./components/InvitationAccept";
import SendInvitation from "./components/SendInvitation";
import NotFound from "./components/NotFound";
import UserProfileView from "./components/UserProfileView";


const AppNav: React.FC = () => {
  const { user, logout } = useAuth();
  return (
    <nav className="mb-4">
      <ul className="flex space-x-4">
        <li>
          <Link to="/" className="text-blue-500 hover:text-blue-700">
            Home
          </Link>
        </li>
        <li>
          <Link to="/projects" className="text-blue-500 hover:text-blue-700">
            Projects
          </Link>
        </li>
        {user ? (
          <>
            <li>
              <Link to="/invite" className="text-blue-500 hover:text-blue-700">
                Invite
              </Link>
            </li>
            <li>
              <Link to="/profile" className="text-green-500">
                {user.username}
              </Link>
            </li>
            <li>
              <button onClick={logout} className="text-red-500">
                Logout
              </button>
            </li>
          </>
        ) : (
          <li>
            <Link to="/login" className="text-blue-500 hover:text-blue-700">
              Login
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/projects/:id" element={<ProjectDetail />} />
      <Route path="/chat/threads/:id" element={<ThreadDetail />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/invite" element={<SendInvitation />} />
      <Route path="/confirm-email/:uid/:token" Component={EmailConfirmation} />
      <Route path="/profile" element={<UserProfile />} />
      <Route path="/profile/:id" element={<UserProfileView />} />
      <Route
        path="/invitations/:invitationId/accept"
        element={<InvitationAccept />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="container mx-auto p-4">
          <AppNav />
          <AppRoutes />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
